import { Action } from 'redux'
import * as Actions from './actionTypes'
import { GetPatientByIdFilters, GetPatientsFilters, Patient } from 'src/common/models/patient'
import { MedicalPaymentData } from '../PatientPayment/types'
import { UserRequest } from 'src/common/models/employee'
import { PaymentPlanData, PaymentPlanReceivable } from 'src/common/models/paymentPlan'
import { GetPatientAccountBalanceFilters, PatientAccountStatement, PatientCreditDebit } from 'src/common/models/patientAccountStatement'
import { MedicalConsultation } from 'src/common/models/medicalConsultation'

export interface GetPatientsAction extends Action {
    filters?: GetPatientsFilters
}
export interface GetPatientsSuccessAction extends Action {
    patients: Patient[]
}
export interface ErrorAction extends Action {
    error?
}
export const getPatientsApi = (filters?: GetPatientsFilters): GetPatientsAction => ({
    type: Actions.GET_PATIENTS_API,
    filters,
})
export const getPatientsSuccess = (patients: Patient[]): GetPatientsSuccessAction => ({
    type: Actions.GET_PATIENTS_SUCCESS,
    patients,
})
export const getPatientsError = (error?): ErrorAction => ({
    type: Actions.GET_PATIENTS_ERROR,
    error,
})

export interface GetPatientByIdAction extends Action {
    id: number
    filters?: GetPatientByIdFilters
}
export interface GetPatientByIdSuccessAction extends Action {
    patient: Patient
}
export const getPatientByIdApi = (id: number, filters?: GetPatientByIdFilters): GetPatientByIdAction => ({
    type: Actions.GET_PATIENT_BY_ID_API,
    id,
    filters,
})
export const getPatientByIdSuccess = (patient: Patient): GetPatientByIdSuccessAction => ({
    type: Actions.GET_PATIENT_BY_ID_SUCCESS,
    patient,
})
export const getPatientByIdError = (error?): ErrorAction => ({
    type: Actions.GET_PATIENT_BY_ID_ERROR,
    error,
})

export interface CreateMedicalPaymentAction extends Action {
    payment: MedicalPaymentData
    user: UserRequest
}
export interface CreateMedicalPaymentSuccessAction extends Action {
    payment: MedicalPaymentData
}
export const createMedicalPaymentApi = (payment: MedicalPaymentData, user: UserRequest): CreateMedicalPaymentAction => ({
    type: Actions.CREATE_MEDICAL_PAYMENT_API,
    payment,
    user,
})
export const createMedicalPaymentSuccess = (payment: MedicalPaymentData): CreateMedicalPaymentSuccessAction => ({
    type: Actions.CREATE_MEDICAL_PAYMENT_SUCCESS,
    payment,
})
export const createMedicalPaymentError = (error?): ErrorAction => ({
    type: Actions.CREATE_MEDICAL_PAYMENT_ERROR,
    error,
})

export interface CreatePaymentPlanAction extends Action {
    plan: PaymentPlanData
    user: UserRequest
}
export interface CreatePaymentPlanSuccessAction extends Action {
    plan: PaymentPlanData
}
export const createPaymentPlanApi = (plan: PaymentPlanData, user: UserRequest): CreatePaymentPlanAction => ({
    type: Actions.CREATE_PAYMENT_PLAN_API,
    plan,
    user,
})
export const createPaymentPlanSuccess = (plan: PaymentPlanData): CreatePaymentPlanSuccessAction => ({
    type: Actions.CREATE_PAYMENT_PLAN_SUCCESS,
    plan,
})
export const createPaymentPlanError = (error?): ErrorAction => ({
    type: Actions.CREATE_PAYMENT_PLAN_ERROR,
    error,
})

export interface GetPaymentPlanReceivablesSuccessAction extends Action {
    receivables: PaymentPlanReceivable[]
}
export const getPaymentPlanReceivablesApi = (): Action => ({
    type: Actions.GET_PAYMENT_PLAN_RECEIVABLES_API,
})
export const getPaymentPlanReceivablesSuccess = (receivables: PaymentPlanReceivable[]): GetPaymentPlanReceivablesSuccessAction => ({
    type: Actions.GET_PAYMENT_PLAN_RECEIVABLES_SUCCESS,
    receivables,
})
export const getPaymentPlanReceivablesError = (error?): ErrorAction => ({
    type: Actions.GET_PAYMENT_PLAN_RECEIVABLES_ERROR,
    error,
})

export interface GetPatientAccountStmtAction extends Action {
    patientId: number
    filters?: GetPatientAccountBalanceFilters
}
export interface GetPatientAccountStmtSuccessAction extends Action {
    accountStmt: PatientAccountStatement[]
}
export const getPatientAccountStmtApi = (patientId: number, filters?: GetPatientAccountBalanceFilters): GetPatientAccountStmtAction => ({
    type: Actions.GET_PATIENT_ACCOUNT_STATEMENT_API,
    patientId,
    filters,
})
export const getPatientAccountStmtSuccess = (accountStmt: PatientAccountStatement[]): GetPatientAccountStmtSuccessAction => ({
    type: Actions.GET_PATIENT_ACCOUNT_STATEMENT_SUCCESS,
    accountStmt,
})
export const getPatientAccountStmtError = (error?): ErrorAction => ({
    type: Actions.GET_PATIENT_ACCOUNT_STATEMENT_ERROR,
    error,
})

export interface GetMedicalConsultationRecordsAction extends Action {
    patientId: number
}
export interface GetMedicalConsultationRecordsSuccessAction extends Action {
    medicalConsultations: MedicalConsultation[]
}
export const getMedicalConsultationRecordsApi = (patientId: number): GetPatientAccountStmtAction => ({
    type: Actions.GET_MEDICAL_CONSULTATION_RECORDS_API,
    patientId,
})
export const getMedicalConsultationRecordsSuccess = (medicalConsultations: MedicalConsultation[]): GetMedicalConsultationRecordsSuccessAction => ({
    type: Actions.GET_MEDICAL_CONSULTATION_RECORDS_SUCCESS,
    medicalConsultations,
})
export const getMedicalConsultationRecordsError = (error?): ErrorAction => ({
    type: Actions.GET_MEDICAL_CONSULTATION_RECORDS_ERROR,
    error,
})

export interface CreatePatientCreditDebitAction extends Action {
    data: PatientCreditDebit
    user: UserRequest
}
export interface CreatePatientCreditDebitSuccessAction extends Action {
    data: PatientCreditDebit
}
export const createPatientCreditDebitApi = (data: PatientCreditDebit, user: UserRequest): CreatePatientCreditDebitAction => ({
    type: Actions.CREATE_PATIENT_CREDIT_DEBIT_API,
    data,
    user,
})
export const createPatientCreditDebitSuccess = (data: PatientCreditDebit): CreatePatientCreditDebitSuccessAction => ({
    type: Actions.CREATE_PATIENT_CREDIT_DEBIT_SUCCESS,
    data,
})
export const createPatientCreditDebitError = (error?): ErrorAction => ({
    type: Actions.CREATE_PATIENT_CREDIT_DEBIT_ERROR,
    error,
})