import { Alert, Form, Input, Row, Select } from "antd"
import { useState } from "react"
import { SystemDescriptions } from "src/common/descriptions/descriptions"
import { UserRequest } from "src/common/models/employee"
import { Patient } from "src/common/models/patient"
import { PatientCreditDebit } from "src/common/models/patientAccountStatement"
import { PaymentPlan } from "src/common/models/paymentPlan"
import { formatToCurrency } from "src/common/parser"
import { ButtonsContainer } from "src/common/styles/styles"
import { amountValidator, getInputRequiredRule } from "src/common/util"
import { ActionButton } from "src/components/ActionButton"
import { ActionButtonType } from "src/components/ActionButton/types"
import { Col10, Col4 } from "src/components/Columns"
import { CurrencyInput } from "src/components/CurrencyInput"
import { OperatedBySelect } from "src/components/OperatedBySelect"
import { MedicalAreaError, MedicalAreaErrorOrigin } from "../../state/types"
import { useSelector } from "react-redux"
import { RootState } from "src/state/reducer"

export enum BalanceMovementType {
    CREDIT = 'CREDIT',
    DEBIT = 'DEBIT',
}

interface Props {
    patient?: Patient
    onFinish: (data: Partial<PatientCreditDebit>, userRequest: UserRequest) => void
    onCancel: () => void
}

interface ReduxProps {
    medicalAreaError?: MedicalAreaError
}

export const PatientCreditDebitForm = (props: Props) => {

    const [userRequest, setUserRequest] = useState<UserRequest | undefined>(undefined)
    const [selectedPlan, setSelectedPlan] = useState<PaymentPlan | undefined>(undefined)
    const [processAmount, setProcessAmount] = useState<number>(0)
    const [selectedPlanId, setSelectedPlanId] = useState<string | undefined>(undefined)
    const [selectedType, setSelectedType] = useState<BalanceMovementType | undefined>(undefined)
    const [alertMessage, setAlertMessage] = useState<string>('')

    const [form] = Form.useForm();

    const descriptions = SystemDescriptions.PAGES.MEDICAL_AREA.PATIENT_CREDIT_DEBIT

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        medicalAreaError: state.medicalArea.error,
    }))

    const handleOperatedByChange = (user: UserRequest | undefined) => {
        form.setFieldsValue({
            createdBy: user?.username ?? undefined
        })

        setUserRequest(user)
    }

    const handleCancelClick = () => {
        props.onCancel()
    }

    const getButtonStatus = (): boolean => {
        return !form.isFieldsTouched(true) ||
            form.getFieldsError().filter(({ errors }) => errors.length)
                .length > 0 || alertMessage.trim().length > 0
    }

    const renderButtons = () => (
        <Form.Item>
            <ButtonsContainer>
                <ActionButton
                    label={descriptions.CANCEL_BUTTON}
                    onClick={handleCancelClick}
                    actionButtonType={ActionButtonType.DESTRUCTIVE}
                />
                <ActionButton
                    label={descriptions.SAVE_BUTTON}
                    htmlType='submit'
                    disabled={getButtonStatus()}
                />
            </ButtonsContainer>
        </Form.Item>
    )

    const handlePaymentPlanChange = (value) => {
        setSelectedPlanId(value)
        const foundPlan = props.patient?.paymentPlanDetail?.find(candidate => candidate.id === value)
        setSelectedPlan(foundPlan)
    }

    const onAmountChange = (value) => {
        const { type, paymentPlan, amount } = form.getFieldsValue()

        if (!value || !paymentPlan || !type) {
            setProcessAmount(0)
            setAlertMessage('')
            return
        }

        const ppBalance = Number(selectedPlan?.amount) - Number(selectedPlan?.amountPayed)

        if (type === BalanceMovementType.CREDIT && Number(amount) > ppBalance) {
            setAlertMessage('Credit Amount must be lower or equal to Payment Plan outstanding')
        } else {
            setAlertMessage('')
        }

        setProcessAmount(Number(value.target.value))
    }

    const handleTypeChange = (value) => {
        setSelectedType(value)
    }

    const renderAlert = () => {
        return alertMessage.trim().length > 0
            && <Alert
                message={alertMessage}
                type="error"
                showIcon
            />
    }

    const renderError = () => {
        return reduxProps.medicalAreaError && reduxProps.medicalAreaError.type === MedicalAreaErrorOrigin.CREATE_PATIENT_CREDIT_DEBIT
            && <Alert
                message={reduxProps.medicalAreaError?.detail?.message || 'Unknown error'}
                type="error"
                showIcon
            />
    }

    const onFinish = (formValues) => {
        if (!userRequest) {
            return
        }

        const data: PatientCreditDebit = {
            patientId: props.patient?.id_patient!,
            type: formValues.type as BalanceMovementType,
            description: formValues.description,
            amount: Number(formValues.amount),
            userId: 0,
        }

        if (formValues.paymentPlan && formValues.paymentPlan !== "NONE") {
            data.paymentPlanId = formValues.paymentPlan
        }

        props.onFinish(data, userRequest)
    }

    const getPlanNewBalance = () => {
        const { type } = form.getFieldsValue()
        const value = type === BalanceMovementType.CREDIT
            ? (selectedPlan?.amount ?? 0) - (selectedPlan?.amountPayed ?? 0) - (processAmount)
            : (selectedPlan?.amount ?? 0) - (selectedPlan?.amountPayed ?? 0) + (processAmount)

        return formatToCurrency(value.toString())
    }

    const getPatientNewBalance = () => {
        const { type } = form.getFieldsValue()
        const value = type === BalanceMovementType.CREDIT
            ? (props.patient?.outstanding ?? 0) - (selectedPlan?.amountPayed ?? 0) - (processAmount)
            : (props.patient?.outstanding ?? 0) - (selectedPlan?.amountPayed ?? 0) + (processAmount)

        formatToCurrency(value.toString())

        return formatToCurrency((value).toString())
    }

    const renderForm = () => {
        return <Form
            layout="vertical"
            requiredMark={false}
            form={form}
            onFinish={onFinish}
        >
            <Row gutter={16}>
                <Col4>
                    <Form.Item
                        label={descriptions.FORM.TYPE.LABEL}
                        name="type"
                        rules={[
                            getInputRequiredRule(descriptions.FORM.TYPE.LABEL)
                        ]}
                    >
                        <Select
                            onChange={handleTypeChange}
                            options={[
                                {
                                    value: BalanceMovementType.CREDIT,
                                    label: descriptions.CREDIT_OPTION,
                                },
                                {
                                    value: BalanceMovementType.DEBIT,
                                    label: descriptions.DEBIT_OPTION,
                                },
                            ]}
                            placeholder={descriptions.FORM.TYPE.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col4>
                <Col10>
                    <Form.Item
                        label={descriptions.FORM.PAYMENT_PLAN.LABEL}
                        name="paymentPlan"
                        rules={[
                            getInputRequiredRule(descriptions.FORM.PAYMENT_PLAN.LABEL)
                        ]}
                    >
                        <Select
                            onChange={handlePaymentPlanChange}
                            options={[
                                {
                                    label: descriptions.FORM.NOT_PAYMENT_PLAN,
                                    value: "NONE",
                                },
                                ...props.patient?.paymentPlanDetail?.filter(plan => !plan.payed)
                                    .map(plan => ({
                                        value: plan.id,
                                        label: `${plan.id} - ${plan.description}`,
                                    })) || [],
                            ]}
                            placeholder={descriptions.FORM.PAYMENT_PLAN.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col10>
                <Col10>
                    <Form.Item
                        label={descriptions.FORM.DESCRIPTION.LABEL}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.DESCRIPTION.LABEL)
                        ]}
                        name="description"
                    >
                        <Input
                            placeholder={descriptions.FORM.DESCRIPTION.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col10>
                <Col4>
                    <Form.Item
                        label={descriptions.FORM.AMOUNT.LABEL}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.DESCRIPTION.LABEL),
                            {
                                validator: (_, value) => {
                                    return amountValidator(value)
                                }
                            },
                        ]}
                        name="amount"
                    >
                        <CurrencyInput
                            placeholder={descriptions.FORM.AMOUNT.PLACEHOLDER}
                            prefix={'Q'}
                            onChange={onAmountChange}
                            disabled={!selectedPlanId || !selectedType}
                        />
                    </Form.Item>
                </Col4>
                <Col4>
                    <Form.Item
                        label={descriptions.FORM.PLAN_CURRENT_BALANCE.LABEL}
                    >
                        <CurrencyInput
                            readOnly
                            prefix={'Q'}
                            value={
                                formatToCurrency(((selectedPlan?.amount ?? 0) - (selectedPlan?.amountPayed ?? 0)).toString())
                            }
                        />
                    </Form.Item>
                </Col4>
                <Col4>
                    <Form.Item
                        label={descriptions.FORM.PLAN_NEW_BALANCE.LABEL}
                    >
                        <CurrencyInput
                            readOnly
                            prefix={'Q'}
                            value={getPlanNewBalance()}
                        />
                    </Form.Item>
                </Col4>
                <Col4>
                    <Form.Item
                        label={descriptions.FORM.PATIENT_CURRENT_BALANCE.LABEL}
                    >
                        <CurrencyInput
                            readOnly
                            prefix={'Q'}
                            value={formatToCurrency(props.patient?.outstanding?.toString() || '0') || '0.00'}
                        />
                    </Form.Item>
                </Col4>
                <Col4>
                    <Form.Item
                        label={descriptions.FORM.PATIENT_NEW_BALANCE.LABEL}
                    >
                        <CurrencyInput
                            readOnly
                            prefix={'Q'}
                            value={getPatientNewBalance()}
                        />
                    </Form.Item>
                </Col4>
                <Col4>
                    <Form.Item
                        label={descriptions.FORM.OPERATED_BY.LABEL}
                        name="createdBy"
                        rules={[
                            getInputRequiredRule(descriptions.FORM.OPERATED_BY.LABEL)
                        ]}
                        validateTrigger="onBlur"
                    >
                        <OperatedBySelect
                            onChange={handleOperatedByChange}
                        />
                    </Form.Item>
                </Col4>
            </Row>
            {renderAlert()}
            {renderError()}
            {renderButtons()}
        </Form>
    }

    return (
        renderForm()
    )
}
