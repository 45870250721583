import { call, put, takeLatest, } from 'redux-saga/effects';
import * as ActionTypes from './actionTypes';
import * as Actions from './action';
import { Urls } from 'src/common/urls';
import { NetworkingProvider } from 'src/features/Security/networking/networkingProvider';
import { GetPatientByIdFilters, GetPatientsFilters } from 'src/common/models/patient';
import { Action } from 'redux'
import { GetPatientAccountBalanceFilters } from 'src/common/models/patientAccountStatement';

const buildPatientsQuery = (filters?: GetPatientsFilters): string => {
    const params: string[] = []
    if (!filters) {
        return ''
    }

    if (filters.paymentPlan) {
        params.push(`paymentPlan=${filters.paymentPlan}`)
    }

    return params.length > 0
        ? `?${params.join('&')}`
        : ``
}

function* getPatientsApi(action: Actions.GetPatientsAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getAppInterface().get, Urls.BACKEND.PATIENT.GET(buildPatientsQuery(action.filters)))
        yield put(Actions.getPatientsSuccess(response.data))
    } catch (err) {
        yield put(Actions.getPatientsError(err));
    }
}

const buildPatientByIdQuery = (filters?: GetPatientByIdFilters): string => {
    const params: string[] = []
    if (!filters) {
        return ''
    }

    if (filters.populated) {
        params.push(`populated=${filters.populated}`)
    }
    if (filters.summary) {
        params.push(`summary=${filters.summary}`)
    }

    return params.length > 0
        ? `?${params.join('&')}`
        : ``
}

function* getPatientByIdApi(action: Actions.GetPatientByIdAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getAppInterface().get, Urls.BACKEND.PATIENT.GET_BY_ID(action.id, buildPatientByIdQuery(action.filters)))
        yield put(Actions.getPatientByIdSuccess(response.data))
    } catch (err) {
        yield put(Actions.getPatientByIdError(err));
    }
}

function* createMedicalPaymentApi(action: Actions.CreateMedicalPaymentAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getAppInterface().post, Urls.BACKEND.MEDICAL_PAYMENT.CREATE, {
            data: action.payment,
            user: action.user,
        })
        yield put(Actions.createMedicalPaymentSuccess(response.data))
    } catch (err: any) {
        yield put(Actions.createMedicalPaymentError(err?.response?.data?.error ?? err));
    }
}

function* createPaymentPlanApi(action: Actions.CreatePaymentPlanAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getAppInterface().post, Urls.BACKEND.PAYMENT_PLAN.CREATE, {
            data: action.plan,
            user: action.user,
        })
        yield put(Actions.createPaymentPlanSuccess(response.data))
    } catch (err: any) {
        yield put(Actions.createPaymentPlanError(err?.response?.data?.error ?? err));
    }
}

function* getPaymentPlanReceivablesApi(action: Action) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getAppInterface().get, Urls.BACKEND.PAYMENT_PLAN.GET_RECEIVABLES)
        yield put(Actions.getPaymentPlanReceivablesSuccess(response.data))
    } catch (err) {
        yield put(Actions.getPaymentPlanReceivablesError(err));
    }
}

const buildPatientAccStmtQuery = (filters?: GetPatientAccountBalanceFilters): string => {
    const params: string[] = []
    if (!filters) {
        return ''
    }

    if (filters.dateFrom && filters.dateTo) {
        params.push(`dateFrom=${filters.dateFrom}`)
        params.push(`dateTo=${filters.dateTo}`)
    }
    if (filters.paymentPlanId) {
        params.push(`paymentPlanId=${filters.paymentPlanId}`)
    }

    return params.length > 0
        ? `?${params.join('&')}`
        : ``
}

function* getPatientAccountStmtApi(action: Actions.GetPatientAccountStmtAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getAppInterface().get, Urls.BACKEND.PATIENT_ACCOUNT_STMT.GET(action.patientId, buildPatientAccStmtQuery(action.filters)))
        yield put(Actions.getPatientAccountStmtSuccess(response.data))
    } catch (err) {
        yield put(Actions.getPatientAccountStmtError(err));
    }
}

function* getPatientMedicalConsultationsApi(action: Actions.GetMedicalConsultationRecordsAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getAppInterface().get, Urls.BACKEND.MEDICAL_CONSULTATION.GET_CONSULTATION_RECORDS(action.patientId))
        yield put(Actions.getMedicalConsultationRecordsSuccess(response.data))
    } catch (err) {
        yield put(Actions.getMedicalConsultationRecordsError(err));
    }
}

function* createPatientCreditDebitApi(action: Actions.CreatePatientCreditDebitAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getAppInterface().post, Urls.BACKEND.PATIENT_ACCOUNT_STMT.CREATE_CREDIT_DEBIT, {
            data: action.data,
            user: action.user,
        })
        yield put(Actions.createPatientCreditDebitSuccess(response.data))
    } catch (err: any) {
        yield put(Actions.createPatientCreditDebitError(err?.response?.data?.error ?? err));
    }
}

export default function* medicalAreaSagas() {
    yield takeLatest(ActionTypes.GET_PATIENTS_API, getPatientsApi)
    yield takeLatest(ActionTypes.GET_PATIENT_BY_ID_API, getPatientByIdApi)
    yield takeLatest(ActionTypes.CREATE_MEDICAL_PAYMENT_API, createMedicalPaymentApi)
    yield takeLatest(ActionTypes.CREATE_PAYMENT_PLAN_API, createPaymentPlanApi)
    yield takeLatest(ActionTypes.GET_PAYMENT_PLAN_RECEIVABLES_API, getPaymentPlanReceivablesApi)
    yield takeLatest(ActionTypes.GET_PATIENT_ACCOUNT_STATEMENT_API, getPatientAccountStmtApi)
    yield takeLatest(ActionTypes.GET_MEDICAL_CONSULTATION_RECORDS_API, getPatientMedicalConsultationsApi)
    yield takeLatest(ActionTypes.CREATE_PATIENT_CREDIT_DEBIT_API, createPatientCreditDebitApi)
}