import { Languages } from "../../types";
import { SidebarDescriptions } from "./types";

const DescriptionsEnUs: SidebarDescriptions = {
    MEDICAL_AREA: {
        TITLE: 'Medical Area',
        ITEMS: {
            MEDICAL_PAYMENT: {
                DISPLAY_NAME: 'Create payment',
                TOOLTIP: '',
            },
            PAYMENT_PLAN: {
                DISPLAY_NAME: 'Create payment Plan',
                TOOLTIP: '',
            },
            PAYMENT_PLAN_RECEIVABLES: {
                DISPLAY_NAME: 'Account receivables',
                TOOLTIP: '',
            },
            PATIENTS: {
                DISPLAY_NAME: 'Patients Records',
                TOOLTIP: '',
            },
            PATIENT_CREDIT_DEBIT: {
                DISPLAY_NAME: 'Create credit/debit patient',
                TOOLTIP: '',
            },
        },
    },
    SETTINGS: {
        TITLE: "Settings",
        ITEMS: {
            MEDICAL_AREA: {
                DISPLAY_NAME: 'Medical Area',
                TOOLTIP: '',
            }
        },
    },
    GERENCY: {
        TITLE: "Gerency",
        ITEMS: {
            REPORTS: {
                DISPLAY_NAME: 'Reports',
                TOOLTIP: '',
            },
            SERVICE_DEBIT_RECORDS: {
                DISPLAY_NAME: 'Service Debit Records',
                TOOLTIP: '',
            },
        }
    },
    HOME: {
        TITLE: 'Home',
        ITEMS: {
        },
    },
}

const DescriptionsEsUs: SidebarDescriptions = {
    MEDICAL_AREA: {
        TITLE: 'Área Médica',
        ITEMS: {
            MEDICAL_PAYMENT: {
                DISPLAY_NAME: 'Crear pago',
                TOOLTIP: '',
            },
            PAYMENT_PLAN: {
                DISPLAY_NAME: 'Crear Plan de Pago',
                TOOLTIP: '',
            },
            PAYMENT_PLAN_RECEIVABLES: {
                DISPLAY_NAME: 'Cuentas por cobrar',
                TOOLTIP: '',
            },
            PATIENTS: {
                DISPLAY_NAME: 'Registro de Pacientes',
                TOOLTIP: '',
            },
            PATIENT_CREDIT_DEBIT: {
                DISPLAY_NAME: 'Crear crédito/débito paciente',
                TOOLTIP: '',
            },
        },
    },
    SETTINGS: {
        TITLE: "Configuraciones",
        ITEMS: {
            MEDICAL_AREA: {
                DISPLAY_NAME: 'Area Médica',
                TOOLTIP: '',
            }
        },
    },
    GERENCY: {
        TITLE: "Gerencia",
        ITEMS: {
            REPORTS: {
                DISPLAY_NAME: 'Reportes',
                TOOLTIP: '',
            },
            SERVICE_DEBIT_RECORDS: {
                DISPLAY_NAME: 'Registro de Cobros de Servicio',
                TOOLTIP: '',
            },
        }
    },
    HOME: {
        TITLE: 'Inicio',
        ITEMS: {
        },
    },
}

const sidebarDictionary = {}
sidebarDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
sidebarDictionary[Languages.SPANISH_US] = DescriptionsEsUs

export const getSidebarDescriptions = (systemLang: Languages): SidebarDescriptions => (
    sidebarDictionary[systemLang]
)