import { Route, Routes } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useEffect } from "react"
import { Layout } from "antd"
import { Dashboard } from "src/features/dashboard"
import * as Styles from './styles'
import { Navbar } from "src/components/navbar"
import { Sidebar } from "src/components/Sidebar"
import { BreadCrumb } from "src/components/BreadCrumb"
import { Footer } from "src/components/footer"
import { SideMenu } from "src/components/Drawer"
import { toggleDrawerOpenStatus } from "src/components/Sidebar/state/action"
import { Urls } from "src/common/urls"
import { PatientPayment } from "src/features/MedicalArea/PatientPayment"
import { CreatePaymentPlan } from "src/features/MedicalArea/CreatePaymentPlan"
import { ServiceDebitRecords } from "src/features/ServiceDebit/ServiceDebitRecords"
import { MedicalAreaSettings } from "src/features/Settings/MedicalArea"
import { ContentContainer } from "src/common/styles/styles"
import { checkIfSystemIsEmbed } from "src/common/util"
import { PaymentPlanReceivables } from "src/features/MedicalArea/PaymentPlanReceivables"
import { MedicalPaymentsReport } from "src/features/Reports/MedicalArea/Payments"
import { Reports } from "src/features/Reports/Main"
import { Patients } from "src/features/MedicalArea/Patient/Patients"
import { PatientProfile } from "src/features/MedicalArea/Patient/PatientProfile"
import { PatientCreditDebitPage } from "src/features/MedicalArea/PatientCreditDebit"

export const AdminRouter = () => {
    const dispatch = useDispatch()
    const IS_SITE_EMBED: boolean = checkIfSystemIsEmbed()

    useEffect(() => {
        // if (!reduxProps.user) {
        //     const user = getSessionData().user
        //     if (user) {
        //         dispatch(loginSuccess(user))
        //     } else {
        //         navigate(Urls.FRONTEND.LOGOUT)
        //     }
        // }

        // if (!reduxProps.company) {
        //     dispatch(getCompany())
        // }

        // if (!reduxProps.settings) {
        //     dispatch(getSettings())
        // }
    }, [])

    const renderRoutes = () => {
        return <Styles.ContentContainer>
            <ContentContainer>
                <Routes>
                    <Route path={Urls.FRONTEND.MEDICAL_AREA.PAYMENT_ADD} element={<PatientPayment />} />
                    <Route path={Urls.FRONTEND.MEDICAL_AREA.CREATE_PAYMENT_PLAN} element={<CreatePaymentPlan />} />
                    <Route path={Urls.FRONTEND.MEDICAL_AREA.PAYMENT_PLAN_RECEIVABLES} element={<PaymentPlanReceivables />} />
                    <Route path={Urls.FRONTEND.MEDICAL_AREA.PATIENTS} element={<Patients />} />
                    <Route path={Urls.FRONTEND.MEDICAL_AREA.PATIENT_PROFILE} element={<PatientProfile />} />
                    <Route path={Urls.FRONTEND.MEDICAL_AREA.PATIENT_CREDIT_DEBIT} element={<PatientCreditDebitPage />} />

                    <Route path={Urls.FRONTEND.SERVICE_DEBIT.RECORDS} element={<ServiceDebitRecords />} />

                    <Route path={Urls.FRONTEND.SETTINGS.MEDICAL_AREA} element={<MedicalAreaSettings />} />

                    <Route path={Urls.FRONTEND.REPORTS.MAIN} element={<Reports />} />
                    <Route path={Urls.FRONTEND.REPORTS.MEDICAL_AREA.PAYMENTS} element={<MedicalPaymentsReport />} />

                    <Route path="*" element={<Dashboard />} />
                </Routes>
            </ContentContainer>
        </Styles.ContentContainer>
    }

    const renderDrawer = () => {
        return <SideMenu
            placement={'left'}
            closable={true}
            onClose={() => { dispatch(toggleDrawerOpenStatus(false)) }}
        >
            <Sidebar
                collapsible={false}
                collapsed={false}
            />
        </SideMenu>
    }

    return (
        <Layout>
            {!IS_SITE_EMBED && <Sidebar />}
            {renderDrawer()}
            <Layout>
                {!IS_SITE_EMBED && <Navbar />}
                {<BreadCrumb />}
                {renderRoutes()}
                {!IS_SITE_EMBED && <Footer />}
            </Layout>
        </Layout>
    )
}
