import { SystemConstants } from "src/common/constants";

const GET_PATIENTS = 'GET_PATIENTS';
export const GET_PATIENTS_API = GET_PATIENTS + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_PATIENTS_SUCCESS = GET_PATIENTS + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_PATIENTS_ERROR = GET_PATIENTS + SystemConstants.API_LOADER.ERROR_SUFFIX;

const GET_PATIENT_BY_ID = 'GET_PATIENT_BY_ID';
export const GET_PATIENT_BY_ID_API = GET_PATIENT_BY_ID + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_PATIENT_BY_ID_SUCCESS = GET_PATIENT_BY_ID + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_PATIENT_BY_ID_ERROR = GET_PATIENT_BY_ID + SystemConstants.API_LOADER.ERROR_SUFFIX;

const CREATE_MEDICAL_PAYMENT = 'CREATE_MEDICAL_PAYMENT';
export const CREATE_MEDICAL_PAYMENT_API = CREATE_MEDICAL_PAYMENT + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const CREATE_MEDICAL_PAYMENT_SUCCESS = CREATE_MEDICAL_PAYMENT + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const CREATE_MEDICAL_PAYMENT_ERROR = CREATE_MEDICAL_PAYMENT + SystemConstants.API_LOADER.ERROR_SUFFIX;

const CREATE_PAYMENT_PLAN = 'CREATE_PAYMENT_PLAN';
export const CREATE_PAYMENT_PLAN_API = CREATE_PAYMENT_PLAN + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const CREATE_PAYMENT_PLAN_SUCCESS = CREATE_PAYMENT_PLAN + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const CREATE_PAYMENT_PLAN_ERROR = CREATE_PAYMENT_PLAN + SystemConstants.API_LOADER.ERROR_SUFFIX;

const GET_PAYMENT_PLAN_RECEIVABLES = 'GET_PAYMENT_PLAN_RECEIVABLES';
export const GET_PAYMENT_PLAN_RECEIVABLES_API = GET_PAYMENT_PLAN_RECEIVABLES + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_PAYMENT_PLAN_RECEIVABLES_SUCCESS = GET_PAYMENT_PLAN_RECEIVABLES + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_PAYMENT_PLAN_RECEIVABLES_ERROR = GET_PAYMENT_PLAN_RECEIVABLES + SystemConstants.API_LOADER.ERROR_SUFFIX;

const GET_PATIENT_ACCOUNT_STATEMENT = 'GET_PATIENT_ACCOUNT_STATEMENT';
export const GET_PATIENT_ACCOUNT_STATEMENT_API = GET_PATIENT_ACCOUNT_STATEMENT + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_PATIENT_ACCOUNT_STATEMENT_SUCCESS = GET_PATIENT_ACCOUNT_STATEMENT + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_PATIENT_ACCOUNT_STATEMENT_ERROR = GET_PATIENT_ACCOUNT_STATEMENT + SystemConstants.API_LOADER.ERROR_SUFFIX;

const GET_MEDICAL_CONSULTATION_RECORDS = 'GET_MEDICAL_CONSULTATION_RECORDS';
export const GET_MEDICAL_CONSULTATION_RECORDS_API = GET_MEDICAL_CONSULTATION_RECORDS + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_MEDICAL_CONSULTATION_RECORDS_SUCCESS = GET_MEDICAL_CONSULTATION_RECORDS + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_MEDICAL_CONSULTATION_RECORDS_ERROR = GET_MEDICAL_CONSULTATION_RECORDS + SystemConstants.API_LOADER.ERROR_SUFFIX;

const CREATE_PATIENT_CREDIT_DEBIT = 'CREATE_PATIENT_CREDIT_DEBIT';
export const CREATE_PATIENT_CREDIT_DEBIT_API = CREATE_PATIENT_CREDIT_DEBIT + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const CREATE_PATIENT_CREDIT_DEBIT_SUCCESS = CREATE_PATIENT_CREDIT_DEBIT + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const CREATE_PATIENT_CREDIT_DEBIT_ERROR = CREATE_PATIENT_CREDIT_DEBIT + SystemConstants.API_LOADER.ERROR_SUFFIX;