import { Languages } from "../types";
import { patientCreditDebitDictionary } from "./PatientCreditDebit";
import { patientProfileDictionary } from "./PatientProfile";
import { patientsDictionary } from "./Patients";
import { paymentPlanReceivablesDictionary } from "./PaymentPlanReceivables";
import { createPaymentPlanDictionary } from "./createPaymentPlan";
import { medicalPaymentCreateDictionary } from "./medicalPayment";
import { MedicalAreaPagesDescriptions } from "./types";

export const getMedicalAreaDescriptions = (systemLang: Languages): MedicalAreaPagesDescriptions => ({
    MEDICAL_PAYMENT: medicalPaymentCreateDictionary[systemLang],
    CREATE_PAYMENT_PLAN: createPaymentPlanDictionary[systemLang],
    PAYMENT_PLAN_RECEIVABLES: paymentPlanReceivablesDictionary[systemLang],
    PATIENTS: patientsDictionary[systemLang],
    PATIENT_PROFILE: patientProfileDictionary[systemLang],
    PATIENT_CREDIT_DEBIT: patientCreditDebitDictionary[systemLang],
})