import { Assets } from "./assets"
import { SystemConstants } from "./constants"
import moment from 'moment'
import { SystemDescriptions } from "./descriptions/descriptions"

export const isObjectEmpty = (obj: any) => Object.keys(obj).length === 0

export const formatToCurrency = (value: number, symbol?: string, decimals?: number) => {
    value = !value ? 0 : value as number
    const numberFormatted = Number(value || 0).toFixed(decimals ?? 2)
    return symbol ? ` ${symbol}${numberFormatted}` : numberFormatted
}

export const parseDataKey = (data: any[]) => {
    return data.map((item: any, idx: number) => {
        item.key = (idx + 1)
        item.idx = (idx + 1)
        return item
    })
}

export const isValidCurrency = (inputValue: string): boolean => {
    const currencyPattern = /^[0-9]+(,[0-9]{3})*(?:\.[0-9]{1,2})?$/
    return currencyPattern.test(inputValue)
}

export const formatToDisplayDatetime = (datetimeDBFormat: string = '') => moment(datetimeDBFormat).format(SystemConstants.DATETIME_FORMAT_DISPLAY)

export const formatToDisplayDate = (datetimeDBFormat: string = '') => moment(datetimeDBFormat).format(SystemConstants.DATE_FORMAT)

export const buildLocalImagePath = (imgName: string): string => {
    return imgName.length === 0 ? Assets.NO_IMAGE_ITEM : `${Assets.PRODUCTS_PATH}${imgName}`
}

export const stringToBase64 = (inputString: string): string => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
    const padding = '=';

    let result = '';
    let paddingCount = 0;

    for (let i = 0; i < inputString.length; i += 3) {
        const a = inputString.charCodeAt(i);
        const b = inputString.charCodeAt(i + 1);
        const c = inputString.charCodeAt(i + 2);

        const index1 = a >> 2;
        const index2 = ((a & 3) << 4) | (b >> 4);
        const index3 = ((b & 15) << 2) | (c >> 6);
        const index4 = c & 63;

        result += characters.charAt(index1) + characters.charAt(index2) + characters.charAt(index3) + characters.charAt(index4);

        if (i + 2 >= inputString.length) {
            paddingCount = i + 2 - inputString.length + 1;
        }
    }

    if (paddingCount > 0) {
        result = result.slice(0, -paddingCount) + padding.repeat(paddingCount);
    }

    return result;
}

export const checkIfSystemIsEmbed = (): boolean => {
    const queryParameters: any = new URLSearchParams(window.location.search)
    return queryParameters.get(SystemConstants.QUERY_PARAMS.EMBED) || queryParameters.get(SystemConstants.QUERY_PARAMS.ACCESS_TOKEN)
}

export const cleanTextToCurrency = (value: string) => value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');

export const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

export const getInputRequiredRule = (fieldName: string): { required: boolean, message: string } => {
    return {
        required: true,
        message: SystemDescriptions.PAGES.COMMON.FORM.REQUIRED_FIELD(fieldName)
    }
}

const isValidCurrencyAmount = (value): boolean => {
    const currencyRegex = /^\d+(\.\d{1,6})?$/;

    if (!currencyRegex.test(value)) {
        return false
    }

    return true
}

export const amountValidator = (value, isZeroValid: boolean = false): Promise<string> => {
    if (!isValidCurrencyAmount(value)) {
        return Promise.reject("Invalid amount")
    }

    if (!isZeroValid && parseFloat(value) <= 0) {
        return Promise.reject("Amount must be greater than zero")
    } else if (isZeroValid && parseFloat(value) < 0) {
        return Promise.reject("Amount must be greater or equal than zero")
    }

    return Promise.resolve("")
}