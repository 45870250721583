import { ReportParams } from "./models/Reports/report";

export const Urls = {
    BACKEND: {
        PATIENT: {
            GET: (queryParams?: string) => `/patients${queryParams ?? ""}`,
            GET_BY_ID: (id: number, queryParams?: string) => `/patient/${id}${queryParams ?? ""}`,
        },
        PATIENT_ACCOUNT_STMT: {
            GET: (patientId: number, queryParams?: string) => `/patient-account-statement/${patientId}${queryParams ?? ""}`,
            CREATE_CREDIT_DEBIT: `/patient-credit-debit`,
        },
        EMPLOYEE: {
            GET: (queryParams?: string) => `/employers${queryParams ?? ""}`,
        },
        MEDICAL_CONSULTATION: {
            CREATE: `/consultation-type`,
            GET: (queryParams?: string) => `/consultation-types${queryParams ?? ""}`,
            GET_BY_ID: (id: number) => `/consultation-type/${id}`,
            UPDATE: `/consultation-type`,
            GET_ORIGINS: `/consultation-origins`,
            GET_CONSULTATION_RECORDS: (id: number) => `/consultations/${id}`,
        },
        MEDICAL_PAYMENT: {
            CREATE: `/medical-payment`,
        },
        PAYMENT_PLAN: {
            CREATE: `/payment-plan`,
            GET_RECEIVABLES: `/payment-plan-receivables`,
        },
        REPORTS: {
            MEDICAL_AREA: {
                PAYMENTS: (params: ReportParams) => `/report-medical-payments?dateFrom=${params.dateFrom}&dateTo=${params.dateTo}&view=${params.view}${params.consultationType ? `&consultationType=${params.consultationType}` : ''}`,
            },
        },
        SERVICE_DEBIT: {
            GET: (queryParams?: string) => `/service-debit-records${queryParams ?? ""}`,
        },

        LOGIN: () => `/login`,
        ITEMS: {
            GET: '/items',
            GET_BY_ID: (itemId: number) => `/item/${itemId}`,
            CREATE: '/item',
            UPDATE: '/item',
        },
        ITEM_CATEGORIES: {
            GET: '/categories',
            CREATE: '/category',
            UPDATE: '/category',
        },
        ITEM_SUBCATEGORIES: {
            GET: '/subcategories',
            CREATE: '/subcategory',
            UPDATE: '/subcategory',
        },
        USERS: {
            GET: '/users',
            CREATE: '/user',
            GET_BY_ID: (userId: number) => `/user/${userId}`,
            RESET_PASSWORD: '/user/reset',
            UPDATE: '/user',
            VALIDATE_UNIQUE_USERNAME: (username: string, userId?: number) => `/username?username=${username}&userId=${userId}`,
        },
        USER_ROLE: {
            GET: '/roles',
            CREATE: '/role',
        },
        VARIANT: {
            GET: '/variants',
            CREATE: '/variant',
            UPDATE: '/variant',
        },
        ORDER: {
            GET: (search: string) => `/orders?${search} `,
            CREATE: '/order',
            GET_ORDER: (orderId: number) => `/order/${orderId}`,
            CREATE_PAYMENT: `/payment`,
            GET_RECORDS: (search: string) => `/orders-view?${search} `,
            DELETE: (userId: number) => `/order/${userId}`,
        },
        LOUNGE: {
            GET: '/lounges',
            CREATE: '/lounge',
        },
        TABLE: {
            GET: '/tables',
        },
        SETTINGS: {
            GET_COMPANY: '/company',
            UPDATE_COMPANY: '/company',
            GET_SETTINGS: '/settings',
            UPDATE_SETTING: '/setting',
        },
    },
    FRONTEND: {
        MEDICAL_AREA: {
            PAYMENT_ADD: `/medical-payment`,
            CREATE_PAYMENT_PLAN: `/payment-plan`,
            PAYMENT_PLAN_RECEIVABLES: `/payment-plan-receivables`,
            PATIENTS: `/patients`,
            PATIENT_PROFILE: `/patient-profile/:id`,
            PATIENT_CREDIT_DEBIT: `/patient-credit-debit`,
        },
        REPORTS: {
            MAIN: `/reports`,
            MEDICAL_AREA: {
                PAYMENTS: `/report-medical-payments`,
            }
        },
        SERVICE_DEBIT: {
            RECORDS: `/service-debit-records`,
        },
        SETTINGS: {
            MEDICAL_AREA: `/medical-area-settings`,
        },

        HOME: '/',
        LOGIN: '/login',
        LOGOUT: '/logout',
        USERS: {
            RECORDS: '/users',
            CREATE: '/user',
            EDIT: `/user/:id`,
            EDIT_URL: (userId: number) => `/user/${userId}`,
        },
        ITEMS: {
            RECORDS: '/items',
            CREATE: '/item',
            EDIT: `/item/:id`,
            EDIT_URL: (itemId: number) => `/item/${itemId}`,
        },
        ITEM_PROPERTIES: {
            RECORDS: '/item-properties',
            CREATE: '/item-property',
        },
        ITEM_CATEGORY: {
            CREATE: '/category',
        },
        POS: {
            POS: '/POS',
            ORDERS: '/orders',
        },
        // REPORTS: {
        //     ORDER_RECORDS: '/report-orders',
        //     SOLD_UNITS: '/report-units',
        //     UTILITY: '/report-utility',
        // }
    },
};