import { ApiError } from "src/features/Security/networking/types";

export enum MedicalAreaErrorOrigin {
    GET_PATIENTS = 'GET_PATIENTS',
    GET_PATIENT_BY_ID = 'GET_PATIENT_BY_ID',
    CREATE_MEDICAL_PAYMENT = 'CREATE_MEDICAL_PAYMENT',
    CREATE_PAYMENT_PLAN = 'CREATE_PAYMENT_PLAN',
    GET_PAYMENT_PLAN_RECEIVABLES = 'GET_PAYMENT_PLAN_RECEIVABLES',
    GET_PATIENT_ACCOUNT_STMT = 'GET_PATIENT_ACCOUNT_STMT',
    GET_MEDICAL_CONSULTATION_RECORDS = 'GET_MEDICAL_CONSULTATION_RECORDS',
    CREATE_PATIENT_CREDIT_DEBIT = 'CREATE_PATIENT_CREDIT_DEBIT',
}

export interface MedicalAreaError {
    type: MedicalAreaErrorOrigin,
    detail: ApiError
}