import { useDispatch, useSelector } from "react-redux"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { ContentContainer, PageCard } from "src/common/styles/styles"
import { SearchableTable } from "src/components/SearchableTable"
import { RootState } from "src/state/reducer"
import { useEffect, useState } from "react"
import { SystemDescriptions } from "src/common/descriptions/descriptions"
import { summaryColumns, tableColumns } from "./config"
import { Patient } from "src/common/models/patient"
import { GET_PATIENTS_API } from "../../state/actionTypes"
import { getPatientsApi } from "../../state/action"
import { searchableTableUtils } from "src/components/SearchableTable/utils"
import { Button, Space, Tooltip } from "antd"
import { EditOutlined, UserOutlined } from '@ant-design/icons'
import moment from "moment"
import { SystemConstants } from "src/common/constants"
import { DateRanges } from "src/components/SearchableTable/types"

interface ReduxProps {
    isLoadingData: boolean
    patients: Patient[]
}

export const Patients = () => {
    const dispatch = useDispatch()

    const [_, setDateRanges] = useState<DateRanges>({
        startDate: moment().startOf('month').format(SystemConstants.DATETIME_QUERY(true)),
        endDate: moment().format(SystemConstants.DATETIME_QUERY(false)),
    })

    const descriptions = SystemDescriptions.PAGES.MEDICAL_AREA.PATIENTS
    const commonDescriptions = SystemDescriptions.PAGES.COMMON

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        isLoadingData: serviceLoading(state, [GET_PATIENTS_API]),
        patients: state.medicalArea.patients,
    }))

    useEffect(() => {
        dispatch(getPatientsApi())
    }, [])

    const handleDateRangesChange = async (dateRanges: DateRanges) => {
        setDateRanges({
            startDate: moment(dateRanges.startDate).format(SystemConstants.DATETIME_QUERY(true)),
            endDate: moment(dateRanges.endDate).format(SystemConstants.DATETIME_QUERY(false)),
        })
    }

    const tblColumns = [
        ...tableColumns,
        {
            title: descriptions.TABLE_COLUMNS.ACTION,
            key: 'action',
            align: searchableTableUtils.alignment.centerAlign,
            render: (_, record: Patient) => {
                const handleEditClick = (id: number) => {
                    console.log("edit", id)
                }

                return <Space size="middle">
                    <Tooltip title={commonDescriptions.ACTIONS.VIEW}>
                        <Button type="primary" ghost shape="circle"
                            icon={<UserOutlined rev={undefined} />}
                            onClick={() => handleEditClick(record.id_patient!)}
                        />
                    </Tooltip>
                    <Tooltip title={commonDescriptions.ACTIONS.EDIT}>
                        <Button type="primary" ghost shape="circle"
                            icon={<EditOutlined rev={undefined} />}
                            onClick={() => handleEditClick(record.id_patient!)}
                        />
                    </Tooltip>
                </Space>
            },
        },
    ]

    const renderContent = () => (
        < PageCard size="small" title={descriptions.TITLE} >
            <SearchableTable
                showSearch
                searchLabel={descriptions.TABLE.SEARCH_PLACEHOLDER}
                items={reduxProps.patients}
                tableColumns={tblColumns}
                showLoader={reduxProps.isLoadingData}
                summaryColumns={summaryColumns}
                showRangePicker
                rangePickerOnChange={handleDateRangesChange}
            />
        </PageCard >
    )

    return (
        <ContentContainer>
            {renderContent()}
        </ContentContainer>
    )
}
