import { Alert, Button, Divider, Form, Input, Row, Select } from "antd"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { Patient } from "src/common/models/patient"
import { PageCard } from "src/common/styles/styles"
import { CREATE_MEDICAL_PAYMENT_API, GET_PATIENT_BY_ID_API, GET_PATIENTS_API } from "src/features/MedicalArea/state/actionTypes"
import { MedicalAreaError, MedicalAreaErrorOrigin } from "src/features/MedicalArea/state/types"
import { RootState } from "src/state/reducer"
import InnerLoader from "../innerLoader"
import { getPatientByIdApi, getPatientsApi } from "src/features/MedicalArea/state/action"
import { filterOption } from "src/common/util"
import dayjs from 'dayjs'
import { SystemConstants } from "src/common/constants"
import { Col24, Col4, Col8 } from "../Columns"
import { UserOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { CurrencyInput } from "../CurrencyInput"
import { PaymentPlanList } from "src/features/MedicalArea/PatientPayment/PaymentPlanList"
import { formatToCurrency } from "src/common/parser"
import { SystemDescriptions } from "src/common/descriptions/descriptions"

interface ReduxProps {
    patients: Patient[]
    error?: MedicalAreaError
    isGettingPatients: boolean
    isGettingPatientById: boolean
    currentPatient?: Patient
    isCreatingPayment: boolean
    createPaymentSuccess: boolean
}

interface Props {
    onPatientChange: (patient?: Patient) => void
}

export const PatientSearch = (props: Props) => {

    const dispatch = useDispatch()

    const [plansVisible, setPlansVisible] = useState<boolean>(false)

    useEffect(() => {
        dispatch(getPatientsApi({ paymentPlan: "1" }))
    }, [dispatch])

    const [form] = Form.useForm();
    const descriptions = SystemDescriptions.COMPONENTS.PATIENT_SEARCH

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        patients: state.medicalArea.patients,
        error: state.medicalArea.error,
        isGettingPatientById: serviceLoading(state, [GET_PATIENT_BY_ID_API]),
        isGettingPatients: serviceLoading(state, [GET_PATIENTS_API]),
        currentPatient: state.medicalArea.currentPatient,
        isCreatingPayment: serviceLoading(state, [CREATE_MEDICAL_PAYMENT_API]),
        createPaymentSuccess: state.medicalArea.createMedicalPaymentSuccess,
    }))

    useEffect(() => {
        props.onPatientChange(reduxProps.currentPatient)
    }, [reduxProps.currentPatient])

    const renderViewPlans = () => {
        const plans: number = reduxProps.currentPatient?.paymentPlanDetail?.length ?? 0
        return descriptions.PERSONAL_DATA.PLANS_VISIBILITY(plans, !plansVisible)
    }

    const renderExtraButton = () => {
        const handleExtraClick = () => {
            setPlansVisible(!plansVisible)
        }

        return <Button type="link" onClick={handleExtraClick}>{renderViewPlans()}</Button>
    }

    const handlePatientChange = (value: string) => {
        props.onPatientChange(undefined)

        form.setFieldsValue({
            paymentPlanId: undefined,
            description: undefined,
            cash: 0,
            card: 0,
        })

        dispatch(getPatientByIdApi(parseInt(value), { populated: "1" }))
    }

    const renderSearchSection = () => (
        <PageCard
            size="small"
            title={descriptions.PERSONAL_DATA.CUSTOMER.PLACEHOLDER}
        >
            {
                reduxProps.isGettingPatients
                    ? <InnerLoader />
                    : reduxProps.error && reduxProps.error.type === MedicalAreaErrorOrigin.GET_PATIENTS
                        ? <Alert type="error" message={"Error"} banner />
                        :
                        <Select
                            style={{ width: '100%' }}
                            showSearch
                            placeholder={descriptions.PERSONAL_DATA.CUSTOMER.PLACEHOLDER}
                            optionFilterProp="children"
                            onChange={handlePatientChange}
                            filterOption={filterOption}
                            options={reduxProps.patients.map(patient => ({
                                value: `${patient.id_patient ?? 0}`,
                                label: `${patient.name} ${patient.surname} - ${dayjs(patient.birthdate).format(SystemConstants.DATE_FORMAT)} - ${patient.doc_id}`,
                            }))}
                        />
            }
        </PageCard>
    )

    const renderDataSection = () => (
        <>
            {
                reduxProps.isGettingPatientById &&
                <InnerLoader label={descriptions.PERSONAL_DATA.GETTING_PATIENT_DATA} />
            }
            {reduxProps.currentPatient &&
                <PageCard
                    size="small"
                    title={descriptions.PERSONAL_DATA.TITLE}
                    extra={renderExtraButton()}
                >
                    {reduxProps.currentPatient &&
                        <Form
                            layout="vertical"
                            requiredMark={false}
                        >
                            <Row gutter={16}>
                                <Col8>
                                    <Form.Item
                                        label={descriptions.PERSONAL_DATA.CUSTOMER.LABEL}
                                    >
                                        <Input
                                            defaultValue={`${reduxProps.currentPatient?.name} ${reduxProps.currentPatient?.surname} `}
                                            readOnly
                                            prefix={<UserOutlined />}
                                        />
                                    </Form.Item>
                                </Col8>
                                <Col8>
                                    <Form.Item
                                        label={descriptions.PERSONAL_DATA.EMAIL.LABEL}
                                    >
                                        <Input
                                            defaultValue={reduxProps.currentPatient?.email}
                                            readOnly
                                            prefix={<MailOutlined />}
                                        />
                                    </Form.Item>
                                </Col8>
                                <Col4>
                                    <Form.Item
                                        label={descriptions.PERSONAL_DATA.PHONE.LABEL}
                                    >
                                        <Input defaultValue={reduxProps.currentPatient?.phone} readOnly
                                            prefix={<PhoneOutlined />}
                                        />
                                    </Form.Item>
                                </Col4>
                                <Col4>
                                    <Form.Item
                                        label={descriptions.PERSONAL_DATA.BALANCE.LABEL}
                                    >
                                        <CurrencyInput
                                            value={formatToCurrency(reduxProps.currentPatient?.outstanding?.toString() ?? "0")}
                                            readOnly
                                            prefix={'Q'}
                                        />
                                    </Form.Item>
                                </Col4>
                                {
                                    plansVisible &&
                                    <Col24>
                                        <>
                                            <Divider />
                                            <PaymentPlanList
                                                data={reduxProps.currentPatient?.paymentPlanDetail}
                                            />
                                        </>
                                    </Col24>
                                }
                            </Row>
                        </Form>
                    }
                </PageCard>
            }
        </>
    )

    return (
        <>
            {renderSearchSection()}
            {renderDataSection()}
        </>
    )
}
