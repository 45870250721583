import { Col } from "antd"

export interface Props {
    children?: JSX.Element | null
}

const renderColumn = (props: Props, size: number) => {
    return <Col xs={24} sm={12} md={size} lg={size} xl={size}>
        {props?.children}
    </Col>
}

export const Col24 = (props: Props) => {
    return renderColumn(props, 24)
}
export const Col20 = (props: Props) => {
    return renderColumn(props, 20)
}
export const Col18 = (props: Props) => {
    return renderColumn(props, 18)
}
export const Col16 = (props: Props) => {
    return renderColumn(props, 16)
}
export const Col12 = (props: Props) => {
    return renderColumn(props, 12)
}
export const Col10 = (props: Props) => {
    return renderColumn(props, 10)
}
export const Col9 = (props: Props) => {
    return renderColumn(props, 9)
}
export const Col8 = (props: Props) => {
    return renderColumn(props, 8)
}
export const Col6 = (props: Props) => {
    return renderColumn(props, 6)
}
export const Col5 = (props: Props) => {
    return renderColumn(props, 5)
}
export const Col4 = (props: Props) => {
    return renderColumn(props, 4)
}
export const Col3 = (props: Props) => {
    return renderColumn(props, 3)
}
export const Col2 = (props: Props) => {
    return renderColumn(props, 2)
}
export const Col1 = (props: Props) => {
    return renderColumn(props, 1)
}