import { formatToCurrency } from "src/common/parser";
import { Languages } from "../../types";
import { PatientSearchDescriptions } from "./types";
import dayjs from 'dayjs'
import { SystemConstants } from "src/common/constants";
import { PaymentPlan } from "src/common/models/paymentPlan";

const DescriptionsEnUs: PatientSearchDescriptions = {
    PERSONAL_DATA: {
        TITLE: "Personal Data",
        CUSTOMER: {
            LABEL: "Patient",
            PLACEHOLDER: "Select patient",
        },
        EMAIL: {
            LABEL: "Email",
            PLACEHOLDER: "Email",
        },
        PHONE: {
            LABEL: "Phone",
            PLACEHOLDER: "Phone",
        },
        BALANCE: {
            LABEL: "Balance",
            PLACEHOLDER: "Balance",
        },
        GETTING_PATIENT_DATA: "Getting patient data.",
        ERROR_GETTING_PATIENTS: "An error ocurred while getting patients.",
        PLANS_VISIBILITY: (numberOfPlans: number, hide?: boolean) => {
            if (numberOfPlans === 0) {
                return `No Plans`
            }
            return hide ? `Show ${numberOfPlans} Plan(s)` : `Hide ${numberOfPlans} Plan(s)`
        },
        PLAN_DATA_COMPOSSER: (paymentPlan: PaymentPlan) => {
            const datetime: string = dayjs(paymentPlan.datetime).format(SystemConstants.DATETIME_FORMAT_DISPLAY)
            const amount: string = formatToCurrency(paymentPlan.amount.toString())
            const amountPayed: string = formatToCurrency(paymentPlan.amountPayed.toString())
            const outstanding: string = formatToCurrency(
                (parseFloat(paymentPlan.amount.toString()) - parseFloat(paymentPlan.amountPayed.toString())).toString()
            )

            return `Created at: ${datetime}. Total: ${amount}. Payed: ${amountPayed}. Outstanding: ${outstanding}`
        },
    },
}

const DescriptionsEsUs: PatientSearchDescriptions = {
    PERSONAL_DATA: {
        TITLE: "Datos personales",
        CUSTOMER: {
            LABEL: "Paciente",
            PLACEHOLDER: "Seleccione paciente",
        },
        EMAIL: {
            LABEL: "Correo",
            PLACEHOLDER: "Correo",
        },
        PHONE: {
            LABEL: "Teléfono",
            PLACEHOLDER: "Teléfono",
        },
        BALANCE: {
            LABEL: "Saldo",
            PLACEHOLDER: "Saldo",
        },
        GETTING_PATIENT_DATA: "Obeniendo información del paciente.",
        ERROR_GETTING_PATIENTS: "Ha ocurrido un error al obtener los pacientes.",
        PLANS_VISIBILITY: (numberOfPlans: number, hide?: boolean) => {
            if (numberOfPlans === 0) {
                return `Sin planes`
            }
            return hide ? `Mostrar ${numberOfPlans} Plan(es)` : `Ocultar ${numberOfPlans} Plan(es)`
        },
        PLAN_DATA_COMPOSSER: (paymentPlan: PaymentPlan) => {
            const datetime: string = dayjs(paymentPlan.datetime).format(SystemConstants.DATETIME_FORMAT_DISPLAY)
            const amount: string = formatToCurrency(paymentPlan.amount.toString())
            const amountPayed: string = formatToCurrency(paymentPlan.amountPayed.toString())
            const outstanding: string = formatToCurrency(
                (parseFloat(paymentPlan.amount.toString()) - parseFloat(paymentPlan.amountPayed.toString())).toString()
            )

            return `Creado: ${datetime}. Total: ${amount}. Pagado: ${amountPayed}. Pendiente: ${outstanding}`
        },
    },
}

const getPatientSearchDictionary = {}
getPatientSearchDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
getPatientSearchDictionary[Languages.SPANISH_US] = DescriptionsEsUs

export const getPatientSearchDescriptions = (systemLang: Languages): PatientSearchDescriptions => (
    getPatientSearchDictionary[systemLang]
)