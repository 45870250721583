import { Languages } from "../../types";
import { PatientCreditDebitDescriptions } from "./types";

const DescriptionsEnUs: PatientCreditDebitDescriptions = {
    TITLE: "Create Patient Credit/Debit",
    FORM: {
        TITLE: "Credit/Debit Data",
        TYPE: {
            LABEL: "Type",
            PLACEHOLDER: "Select type",
        },
        PAYMENT_PLAN: {
            LABEL: "Payment Plan",
            PLACEHOLDER: "Select Payment Plan",
        },
        DESCRIPTION: {
            LABEL: "Description",
            PLACEHOLDER: "Input description",
        },
        AMOUNT: {
            LABEL: "Amount",
            PLACEHOLDER: "Input amount",
        },
        PLAN_CURRENT_BALANCE: {
            LABEL: "Plan Current Balance",
            PLACEHOLDER: "0",
        },
        PLAN_NEW_BALANCE: {
            LABEL: "Plan New Balance",
            PLACEHOLDER: "0",
        },
        PATIENT_CURRENT_BALANCE: {
            LABEL: "Patient Current Balance",
            PLACEHOLDER: "0",
        },
        PATIENT_NEW_BALANCE: {
            LABEL: "Patient New Balance",
            PLACEHOLDER: "0",
        },
        OPERATED_BY: {
            LABEL: "Operated By",
            PLACEHOLDER: "Select operator",
        },
        ERRORS: {
        },
        DESCRIPTION_COMPOSSER: () => 'THIS NEEDS TO BE BUILD',
        SAVING_TITLE: "Creating Credit/Debit",
        SAVING_DESCRIPTION: "Please wait...",
        NOT_PAYMENT_PLAN: "Don´t apply to Payment Plan",
    },
    SAVE_BUTTON: "Save",
    CANCEL_BUTTON: "Cancel",
    CONFIRM_MODAL: {
        TITLE: 'Save Credit/Debit?',
        DESCRIPTION: "Operation will not be reverted.",
        ACTION_BUTTON: "Save",
        CANCEL_BUTTON: "Go Back",
    },
    CANCEL_MODAL: {
        TITLE: "Cancel Credit/Debit input?",
        DESCRIPTION: "All data will be lost.",
        ACTION_BUTTON: "Yes, Cancel",
        CANCEL_BUTTON: "Keep working",
    },
    SUCCESS_MODAL: {
        TITLE: "Success",
        PRIMARY_LABEL: "Credit/Debit created",
        SECONDARY_LABEL: "Accept",
        DESCRIPTION: "Accept",
    },
    CREDIT_OPTION: "Credit",
    DEBIT_OPTION: "Recharge",
}

const DescriptionsEsUs: PatientCreditDebitDescriptions = {
    TITLE: "Crear Crédito/Débito Paciente",
    FORM: {
        TITLE: "Datos de Crédito/Débito",
        TYPE: {
            LABEL: "Tipo",
            PLACEHOLDER: "Seleccione tipo",
        },
        PAYMENT_PLAN: {
            LABEL: "Plan de Pago",
            PLACEHOLDER: "Seleccione Plan de Pago",
        },
        DESCRIPTION: {
            LABEL: "Descripcion",
            PLACEHOLDER: "Ingrese descripción",
        },
        AMOUNT: {
            LABEL: "Monto",
            PLACEHOLDER: "Ingrese monto",
        },
        PLAN_CURRENT_BALANCE: {
            LABEL: "Plan Saldo Actual",
            PLACEHOLDER: "0",
        },
        PLAN_NEW_BALANCE: {
            LABEL: "Plan Nuevo Saldo",
            PLACEHOLDER: "0",
        },
        PATIENT_CURRENT_BALANCE: {
            LABEL: "Paciente Saldo Actual",
            PLACEHOLDER: "0",
        },
        PATIENT_NEW_BALANCE: {
            LABEL: "Paciente Nuevo Saldo",
            PLACEHOLDER: "0",
        },
        OPERATED_BY: {
            LABEL: "Operado por",
            PLACEHOLDER: "Seleccione operador",
        },
        ERRORS: {
        },
        DESCRIPTION_COMPOSSER: () => 'THIS NEEDS TO BE BUILD',
        SAVING_TITLE: "Guardando Credit/Debit",
        SAVING_DESCRIPTION: "Espere por favor...",
        NOT_PAYMENT_PLAN: "No aplicar a Plan de Pago",
    },
    SAVE_BUTTON: "Guardar",
    CANCEL_BUTTON: "Cancelar",
    CONFIRM_MODAL: {
        TITLE: 'Guardar Crédito/Débito con los datos ingresado?',
        DESCRIPTION: "La operación no podrá ser revertida.",
        ACTION_BUTTON: "Guardar",
        CANCEL_BUTTON: "Regresar",
    },
    CANCEL_MODAL: {
        TITLE: "Cancelar ingreso de Crédito/Débito?",
        DESCRIPTION: "La información ingresada sera borrada.",
        ACTION_BUTTON: "Si, Cancelar",
        CANCEL_BUTTON: "Seguir ingresando",
    },
    SUCCESS_MODAL: {
        TITLE: "Exito",
        PRIMARY_LABEL: "Crédito/Débito creado",
        SECONDARY_LABEL: "Aceptar",
        DESCRIPTION: "Aceptar",
    },
    CREDIT_OPTION: "Crédito",
    DEBIT_OPTION: "Recargo",
}

export const patientCreditDebitDictionary = {}
patientCreditDebitDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
patientCreditDebitDictionary[Languages.SPANISH_US] = DescriptionsEsUs