import { useState } from "react"
import { Patient } from "src/common/models/patient"
import { PageCard } from "src/common/styles/styles"
import { PatientSearch } from "src/components/PatientSearch"
import { PatientCreditDebitForm } from "./PatientCreditDebitForm"
import { CancelConfirmationModal } from "src/components/cancelConfirmationModal"
import { ModalSuccess } from "src/components/ModalSuccess"
import { checkIfSystemIsEmbed } from "src/common/util"
import { SystemConstants } from "src/common/constants"
import { Urls } from "src/common/urls"
import { useNavigate } from "react-router"
import { MedicalAreaError } from "../state/types"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "src/state/reducer"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { CREATE_MEDICAL_PAYMENT_API } from "../state/actionTypes"
import { SystemDescriptions } from "src/common/descriptions/descriptions"
import { UserRequest } from "src/common/models/employee"
import { PatientCreditDebit } from "src/common/models/patientAccountStatement"
import { createPatientCreditDebitApi } from "../state/action"

interface ReduxProps {
    error?: MedicalAreaError
    isCreatingCreditDebit: boolean
    createCreditDebitSuccess: boolean
}

interface RequestData {
    data: PatientCreditDebit
    user: UserRequest
}

export const PatientCreditDebitPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const descriptions = SystemDescriptions.PAGES.MEDICAL_AREA.PATIENT_CREDIT_DEBIT

    const [selectedPatient, setSelectedPatient] = useState<Patient | undefined>()
    const [formValues, setFormValues] = useState<RequestData | undefined>()
    const [modalConfirmationOpen, setModalConfirmationOpen] = useState<boolean>(false)
    const [modalCancelOpen, setModalCancelOpen] = useState<boolean>(false)
    const [actionButtonClicked, setActionButtonClicked] = useState<boolean>(false)

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        error: state.medicalArea.error,
        isCreatingCreditDebit: serviceLoading(state, [CREATE_MEDICAL_PAYMENT_API]),
        createCreditDebitSuccess: state.medicalArea.createPatientCreditDebitSuccess,
    }))

    const handlePatientChange = (patient?: Patient) => {
        setSelectedPatient(patient)
    }

    const onFinish = (creditDebitData: Partial<PatientCreditDebit>, userRequest: UserRequest) => {
        setFormValues({
            data: creditDebitData as PatientCreditDebit,
            user: userRequest,
        })
        setModalConfirmationOpen(true)
    }

    const onCancel = () => {
        setModalCancelOpen(true)
    }

    const handleConfirmationAccept = () => {
        setModalConfirmationOpen(false)
        setActionButtonClicked(true)

        if (!formValues) {
            return
        }
        console.log("data before send", formValues)

        dispatch(createPatientCreditDebitApi(formValues.data, formValues.user))
    }

    const handleCancelAccept = () => {
        const isEmbed = checkIfSystemIsEmbed()

        if (isEmbed) {
            if (window.self !== window.top) {
                window.parent.location.href = SystemConstants.DEFAULT_REDIRECT_URL
            } else {
                window.location.href = SystemConstants.DEFAULT_REDIRECT_URL
            }
        } else {
            navigate(Urls.FRONTEND.HOME)
        }
    }

    const renderModals = () => (
        <>
            <CancelConfirmationModal
                isVisible={modalConfirmationOpen}
                onAccept={handleConfirmationAccept}
                onCancel={() => { setModalConfirmationOpen(false) }}
                title={descriptions.CONFIRM_MODAL.TITLE}
                okText={descriptions.CONFIRM_MODAL.ACTION_BUTTON}
                cancelText={descriptions.CONFIRM_MODAL.CANCEL_BUTTON}
                description={descriptions.CONFIRM_MODAL.DESCRIPTION}
            />
            <CancelConfirmationModal
                isVisible={modalCancelOpen}
                onAccept={handleCancelAccept}
                onCancel={() => { setModalCancelOpen(false) }}
                title={descriptions.CANCEL_MODAL.TITLE}
                okText={descriptions.CANCEL_MODAL.ACTION_BUTTON}
                cancelText={descriptions.CANCEL_MODAL.CANCEL_BUTTON}
                description={descriptions.CANCEL_MODAL.DESCRIPTION}
            />
            <ModalSuccess
                isVisible={reduxProps.createCreditDebitSuccess && actionButtonClicked}
                title={descriptions.SUCCESS_MODAL.TITLE}
                description={descriptions.SUCCESS_MODAL.DESCRIPTION}
                onPrimaryAction={handleCancelAccept}
                onSecondaryActiont={handleCancelAccept}
                primaryLabel={descriptions.SUCCESS_MODAL.PRIMARY_LABEL}
                secondaryLabel={descriptions.SUCCESS_MODAL.SECONDARY_LABEL}
                hideSecondaryButton={true}
            />
        </>
    )

    const renderCreditDebitSection = () => {
        return <PageCard
            size="small"
            title={descriptions.TITLE}
        >
            <PatientCreditDebitForm
                patient={selectedPatient}
                onFinish={onFinish}
                onCancel={onCancel}
            />
        </PageCard>
    }

    return (
        <>
            <PatientSearch
                onPatientChange={handlePatientChange}
            />
            {
                selectedPatient &&
                renderCreditDebitSection()
            }
            {renderModals()}
        </>
    )
}
