import * as Actions from "./action";
import * as ActionTypes from "./actionTypes";
import { parseDataKey } from "src/common/util";
import { MedicalAreaError, MedicalAreaErrorOrigin } from "./types";
import { Patient } from "src/common/models/patient";
import { PaymentPlanReceivable } from "src/common/models/paymentPlan";
import { PatientAccountStatement } from "src/common/models/patientAccountStatement";
import { MedicalConsultation } from "src/common/models/medicalConsultation";

export interface MedicalAreaState {
    error?: MedicalAreaError
    currentPatient?: Patient
    patients: Patient[]
    createMedicalPaymentSuccess: boolean
    createPaymentPlanSuccess: boolean
    paymentPlanReceivables: PaymentPlanReceivable[]
    patientAccountStmt?: PatientAccountStatement[]
    medicalConsultations?: MedicalConsultation[]
    createPatientCreditDebitSuccess: boolean
}

const initialState: MedicalAreaState = {
    patients: [],
    createMedicalPaymentSuccess: false,
    createPaymentPlanSuccess: false,
    paymentPlanReceivables: [],
    createPatientCreditDebitSuccess: false,
}

const medicalAreaReducer = (state: MedicalAreaState = initialState, action: any): MedicalAreaState => {
    switch (action.type) {
        case ActionTypes.GET_PATIENTS_API: {
            return {
                ...state,
                patients: [],
                currentPatient: undefined,
            }
        }
        case ActionTypes.GET_PATIENTS_SUCCESS: {
            const successAction = action as Actions.GetPatientsSuccessAction;

            return {
                ...state,
                patients: parseDataKey(successAction.patients),
                error: undefined
            }
        }
        case ActionTypes.GET_PATIENTS_ERROR: {
            const errorAction = action as Actions.ErrorAction;

            return {
                ...state,
                patients: [],
                error: {
                    type: MedicalAreaErrorOrigin.GET_PATIENTS,
                    detail: errorAction.error
                }
            }
        }
        case ActionTypes.GET_PATIENT_BY_ID_API: {
            return {
                ...state,
                currentPatient: undefined,
            }
        }
        case ActionTypes.GET_PATIENT_BY_ID_SUCCESS: {
            const successAction = action as Actions.GetPatientByIdSuccessAction;

            const patient = successAction.patient
            if (patient.paymentPlanDetail) {
                patient.paymentPlanDetail = JSON.parse(patient.paymentPlanDetail as any)
            }
            patient.outstanding = (patient?.outstanding ?? 0) * -1;

            return {
                ...state,
                currentPatient: successAction.patient,
                error: undefined,
            }
        }
        case ActionTypes.GET_PATIENT_BY_ID_ERROR: {
            const errorAction = action as Actions.ErrorAction;

            return {
                ...state,
                error: {
                    type: MedicalAreaErrorOrigin.GET_PATIENT_BY_ID,
                    detail: errorAction.error
                }
            }
        }
        case ActionTypes.CREATE_MEDICAL_PAYMENT_API: {
            return {
                ...state,
                createMedicalPaymentSuccess: false,
                error: undefined,
            }
        }
        case ActionTypes.CREATE_MEDICAL_PAYMENT_SUCCESS: {
            return {
                ...state,
                createMedicalPaymentSuccess: true,
                error: undefined,
            }
        }
        case ActionTypes.CREATE_MEDICAL_PAYMENT_ERROR: {
            const errorAction = action as Actions.ErrorAction;

            return {
                ...state,
                error: {
                    type: MedicalAreaErrorOrigin.CREATE_MEDICAL_PAYMENT,
                    detail: errorAction.error
                }
            }
        }
        case ActionTypes.CREATE_PAYMENT_PLAN_API: {
            return {
                ...state,
                createPaymentPlanSuccess: false,
                error: undefined,
            }
        }
        case ActionTypes.CREATE_PAYMENT_PLAN_SUCCESS: {
            return {
                ...state,
                createPaymentPlanSuccess: true,
                error: undefined,
            }
        }
        case ActionTypes.CREATE_PAYMENT_PLAN_ERROR: {
            const errorAction = action as Actions.ErrorAction;

            return {
                ...state,
                error: {
                    type: MedicalAreaErrorOrigin.CREATE_PAYMENT_PLAN,
                    detail: errorAction.error
                }
            }
        }
        case ActionTypes.GET_PAYMENT_PLAN_RECEIVABLES_API: {
            return {
                ...state,
                paymentPlanReceivables: [],
            }
        }
        case ActionTypes.GET_PAYMENT_PLAN_RECEIVABLES_SUCCESS: {
            const successAction = action as Actions.GetPaymentPlanReceivablesSuccessAction;

            return {
                ...state,
                paymentPlanReceivables: parseDataKey(successAction.receivables),
            }
        }
        case ActionTypes.GET_PAYMENT_PLAN_RECEIVABLES_ERROR: {
            const errorAction = action as Actions.ErrorAction;

            return {
                ...state,
                patients: [],
                error: {
                    type: MedicalAreaErrorOrigin.GET_PAYMENT_PLAN_RECEIVABLES,
                    detail: errorAction.error
                }
            }
        }
        case ActionTypes.GET_PATIENT_ACCOUNT_STATEMENT_API: {
            return {
                ...state,
                patientAccountStmt: undefined,
            }
        }
        case ActionTypes.GET_PATIENT_ACCOUNT_STATEMENT_SUCCESS: {
            const successAction = action as Actions.GetPatientAccountStmtSuccessAction;

            return {
                ...state,
                patientAccountStmt: parseDataKey(successAction.accountStmt),
            }
        }
        case ActionTypes.GET_PATIENT_ACCOUNT_STATEMENT_ERROR: {
            const errorAction = action as Actions.ErrorAction;

            return {
                ...state,
                patients: [],
                error: {
                    type: MedicalAreaErrorOrigin.GET_PATIENT_ACCOUNT_STMT,
                    detail: errorAction.error
                }
            }
        }
        case ActionTypes.GET_MEDICAL_CONSULTATION_RECORDS_API: {
            return {
                ...state,
                medicalConsultations: undefined,
            }
        }
        case ActionTypes.GET_MEDICAL_CONSULTATION_RECORDS_SUCCESS: {
            const successAction = action as Actions.GetMedicalConsultationRecordsSuccessAction;

            return {
                ...state,
                medicalConsultations: parseDataKey(successAction.medicalConsultations),
            }
        }
        case ActionTypes.GET_MEDICAL_CONSULTATION_RECORDS_ERROR: {
            const errorAction = action as Actions.ErrorAction;

            return {
                ...state,
                patients: [],
                error: {
                    type: MedicalAreaErrorOrigin.GET_MEDICAL_CONSULTATION_RECORDS,
                    detail: errorAction.error
                }
            }
        }
        case ActionTypes.CREATE_PATIENT_CREDIT_DEBIT_API: {
            return {
                ...state,
                createPatientCreditDebitSuccess: false,
                error: undefined,
            }
        }
        case ActionTypes.CREATE_PATIENT_CREDIT_DEBIT_SUCCESS: {
            return {
                ...state,
                createPatientCreditDebitSuccess: true,
            }
        }
        case ActionTypes.CREATE_PATIENT_CREDIT_DEBIT_ERROR: {
            const errorAction = action as Actions.ErrorAction;

            return {
                ...state,
                patients: [],
                error: {
                    type: MedicalAreaErrorOrigin.CREATE_PATIENT_CREDIT_DEBIT,
                    detail: errorAction.error
                }
            }
        }
    }
    return state;
};

export default medicalAreaReducer