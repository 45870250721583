import { CommonPagesDescriptions } from "./Common/types"
import { ReportPagesDescriptions } from "./Reports/types"
import { SettingsPagesDescriptions } from "./Settings/types"
import { OperatedBySelectDescriptions } from "./components/OperatedBySelect/types"
import { PatientSearchDescriptions } from "./components/PatientSearch/types"
import { BreadcrumbDescriptions } from "./components/breadcrumb/types"
import { FooterDescriptions } from "./components/footer/types"
import { NavbarDescriptions } from "./components/navbar/types"
import { SearchableTableDescriptions } from "./components/searchableTable/types"
import { SidebarDescriptions } from "./components/sidebar/types"
import { DashboardPagesDescriptions } from "./dashboard/types"
import { MedicalAreaPagesDescriptions } from "./medicalArea/types"

export enum Languages {
    ENGLISH_US = 'en-us',
    SPANISH_US = 'es-us'
}

export const DefaultSettings = {
    SYSTEM_LANG: Languages.SPANISH_US
}

export interface Descriptions {
    PAGES: {
        COMMON: CommonPagesDescriptions
        DASHBOARD: DashboardPagesDescriptions
        MEDICAL_AREA: MedicalAreaPagesDescriptions
        SETTINGS: SettingsPagesDescriptions
        REPORTS: ReportPagesDescriptions
    }
    COMPONENTS: {
        BREADCRUMB: BreadcrumbDescriptions
        NAVBAR: NavbarDescriptions
        SIDEBAR: SidebarDescriptions
        SEARCHABLE_TABLE: SearchableTableDescriptions
        FOOTER: FooterDescriptions
        OPERATED_BY_SELECT: OperatedBySelectDescriptions
        PATIENT_SEARCH: PatientSearchDescriptions
    }
}

export interface FormItem {
    LABEL: string
    PLACEHOLDER: string
}

export interface ConfirmationModal {
    TITLE: string
    DESCRIPTION: string
    ACTION_BUTTON: string
    CANCEL_BUTTON: string
}

export interface SuccessModal {
    TITLE: string
    PRIMARY_LABEL: string
    SECONDARY_LABEL: string
    DESCRIPTION: string
}

export interface FormPageDescriptions {
    TITLE: string
    SAVE_BUTTON: string
    CANCEL_BUTTON: string
    CONFIRM_MODAL: ConfirmationModal
    CANCEL_MODAL: ConfirmationModal
    SUCCESS_MODAL: SuccessModal
    SUCCESS_SECONDARY_MODAL?: SuccessModal
    ERRORS: {
        REQUIRED_FIELD: (fieldName: string) => string
        SAVE_ACTION: string
        UNKNOWN: string
    }
    MAIN_ACTION_TITLE: string
    MAIN_ACTION_DESCRIPTION: string
}

export interface ListPageDescriptions {
    TITLE: string
    TABLE: {
        ACTION_BUTTON: string
        SEARCH_PLACEHOLDER: string
        LOADING_DATA: string
    }
}